import React from 'react'
import HeroComponent from '../components/HeroComponent'
import { useTranslation } from "react-i18next"
import { Helmet } from 'react-helmet-async'

import './About.scss'

export default function About() {

  const {t} = useTranslation();

  const content = t("about", { returnObjects: true })

  return (
    <div className="about">
      <Helmet>
        <title>{content.pageTitle}</title>
      </Helmet>
      <HeroComponent title={content.title} images={content.images}/>
      <div className="content-wrapper">
        <div className="about__text">
          <p dangerouslySetInnerHTML={{__html: content.text}}></p>
        </div>
      </div>
    </div>
  )
}
