import React from 'react'
import './NotFound.scss'

import { useTranslation } from "react-i18next"
import { useCurrentWidth } from '../hooks/useResize'

import SimpleComponent from '../components/SimpleComponent'

export default function NotFound() {

  const { t } = useTranslation();

  const content = t("notFound", { returnObjects: true })

  const link = {
    text: content.link,
    route: '/'
  }

  const windowWidth = useCurrentWidth();

  return (
    <div className="not-found">
      <SimpleComponent link={link} text={content.text} color={windowWidth < 1024 ? content.colorMobile : content.colorDesktop} />
    </div>
  )
}
