import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import Layout from './components/Layout'

import Home from './pages/Home'
import News from './pages/News'
import About from './pages/About'
// import Submit from './pages/Submit'
import Program from './pages/Program'
import Gallery from './pages/Gallery'
// import Blog from './pages/Blog'
import Sponsors from './pages/Sponsors'
import Nav from './components/Nav'
import Footer from './components/Footer'
import NotFound from './pages/NotFound'

import OfficialSelectionNews from './pages/OfficialSelectionNews'
import VolontiersNews from './pages/VolontiersNews'
import ZombieNews from './pages/ZombieNews'
import WorkshopMaskNews from './pages/WorkshopMaskNews'
import Workshop from './pages/Workshop'
import KristinaNews from './pages/KristinaNews'
import AwardsNews from './pages/AwardsNews'
import OfficialSelection21News from './pages/OfficialSelection21News'
import Volontiers21News from './pages/Volontiers21News'
import JuryNews from './pages/JuryNews'
import MasterclassNews from './pages/MasterclassNews'
import AwardsNews21 from './pages/AwardsNews21'
import OfficialSelection22News from './pages/OfficialSelection22News'
import Volontiers22News from './pages/Volontiers22News'
import Masterclass22News from './pages/Masterclass22News'
import Jury22News from './pages/Jury22News'

import './components/global-styles/_reset.scss'
import './components/global-styles/_global.scss'
import './components/global-styles/_font-families.scss'
import './App.css';

function App() {

  return (
    <React.Fragment>
      <HelmetProvider>
        <Router>
          <main>
            <Layout>
              <Nav />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path={['/news', "/vesti"]} component={News} />
                <Route path={["/official-selection", "/zvanicna-selekcija"]} component={OfficialSelectionNews} />
                <Route path={["/about", "/o-frontu"]} component={About} />
                {/* <Route path={["/submit", "/prijava"]} component={Submit}/> */}
                <Route path="/program" component={Program} />
                <Route path={["/gallery", "/galerija"]} component={Gallery} />
                {/* <Route path="/blog" component={Blog}/> */}
                <Route path={["/sponsors", "/sponzori"]} component={Sponsors} />
                <Route path={"/volontiranje"} component={VolontiersNews} />
                <Route path={"/zombi-setnja"} component={ZombieNews} />
                <Route path={"/radionice-maske"} component={WorkshopMaskNews} />
                <Route path={'/kristina'} component={KristinaNews} />
                <Route path={"/radionice-rezija"} component={Workshop} />
                <Route path={"/nagrade"} component={AwardsNews} />
                <Route path={"/zvanicna-selekcija-21-filmskog-fronta"} component={OfficialSelection21News} />
                <Route path={"/volontiraj-na-21-filmskom-frontu"} component={Volontiers21News} />
                <Route path={"/ziri"} component={JuryNews} />
                <Route path={"/masterclass-na-frontu"} component={MasterclassNews} />
                <Route path={"/nagrade-21-filmski-front"} component={AwardsNews21} />
                <Route path={"/zvanicna-selekcija-22-filmskog-fronta"} component={OfficialSelection22News} />
                <Route path={"/volontiraj-na-22-filmskom-frontu"} component={Volontiers22News} />
                <Route path={"/masterclass-na-22-filmskom-frontu"} component={Masterclass22News} />
                <Route path={"/ziri-22-filmski-front"} component={Jury22News} />
                <Route component={NotFound} />
              </Switch>
              <Footer />
            </Layout>
          </main>
        </Router>
      </HelmetProvider>
    </React.Fragment>
  );
}

export default App;
