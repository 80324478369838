import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async'

import HeroComponent from '../components/HeroComponent'

import "./JuryNews.scss"

export default function Jury22News() {

  const images = {
    mobile: '/assets/images/news-jury-22.png',
    desktop: '/assets/images/news-jury-desktop.png'
  }

  return (
    <div>
      <Helmet>
        <title>Predstavljamo vam ovogodišnji stručni žiri Filmskog Fronta</title>
      </Helmet>
      <HeroComponent title="Predstavljamo vam ovogodišnji stručni žiri Filmskog Fronta" images={images} />

      <div className='jury'>
        <div className='content-wrapper'>
          <div className='jury__text'>
            <p>
              <strong>Žiri Srbija/region selekcije</strong><br /><br />
              Odlukom žirija, u saradnji sa Filmskim centrom Srbije, najboljem filmu u ovoj selekciji dodeljuje se nagrada u iznosu od 120.000 dinara. Članovi ovogodišnjeg stručnog žirija su:
            </p>
          </div>

          <div className='jury__jurors'>
            <div className='jury__jurors-single'>
              <LazyLoadImage effect="blur" src={'/assets/images/jury-emilija.png'} alt="Emilija Gašić" />
              <div>
                <p>
                  <strong>Emilija Gašić</strong> je nagrađivana srpska filmska rediteljka i fotografkinja, rođena u Jugoslaviji. Diplomirala je kinematografiju na NYU Tisch School of the Arts i filmsku režiju na Akademiji umetnosti u Beogradu. Kao snimateljka, pasionirana je radom na filmu i bavi se filmskom fotografijom, eksperimentišući sa različitim formatima. Rediteljka je filma „78 dana“ koji je osvojio nagrade na mnogim prestižnim filmskim festivalima.
                </p>
              </div>
            </div>

            <div className='jury__jurors-single'>
              <LazyLoadImage effect="blur" src={'/assets/images/jury-danilo.png'} alt="Danilo Stanimirović" />
              <div>
                <p>
                  <strong>Danilo Stanimirović</strong> je mladi filmski reditelj i zavisnik od čokolade. Njegovi kratki filmovi „5/3/0“, „Prokop“, „Bambilend“ i „Seobe“ prikazani su na festivalima širom sveta i osvojili su brojne nagrade. Pobednik je prošlogodišnjeg Fronta u regionalnoj selekciji. Trenutno radi kao reditelj jedne televizijske serije dok priprema tri kratka filma.
                </p>
              </div>
            </div>

            <div className='jury__jurors-single'>
              <LazyLoadImage effect="blur" src={'/assets/images/jury-milanka.png'} alt="Milanka Gvoić" />
              <div>
                <p>
                  <strong>Milanka Gvoić</strong> je scenaristkinja, producentkinja i asistentkinja reditelja na više dugometražnih filmova. Poslednji film na kome je radila kao scenaristkinja, producentkinja i asistentkinja reditelja - “Kristina” osvojio je Grand Prix u kategoriji prvih filmova – First Film Award na 33. festivalu FID Marseille u Francuskoj, nagradu Best Emerging Director na 19. Filmskom festivalu Evropskog filma u Sevilji, u Španiji, kao i desetine drugih nagrada i priznanja širom Evrope i sveta. Aktuelni projekat koji producira i za koji je napisala scenario je film o novosađanki Milici Tomić, prvoj ženi urednici novina na Balkanu čije je ljubavno pismo promenilo tok srpske istorije.
                </p>
              </div>
            </div>

            <div className='jury__text'>
              <p>
                <strong>Žiri Front Next generation selekcije</strong><br /><br />
              </p>



              <div className='jury__jurors-single-no-image'>
                <div>
                  <p>
                    <strong>Sonja Rakić</strong>, rođena je u Novom Sadu, gde je završila studije režije na Akademiji umetnosti. Tokom svoje karijere, režirala je više kratkih igranih filmova, muzičkih spotova i reklama, kao i brojne dokumentarne forme u okviru televizijskog rada, a i van njega. Posebno je poznata kao rediteljka popularnog humorističnog kolektiva „Dnevnjak“. Trenutno radi na postprodukciji svog dugometražnog dokumentarnog filma pod nazivom „I gde je taj princ na belom konju?“ koji je u nastajanju proveo skoro osam godina. Film je prošao kroz neke od najprestižnijih evropskih radionica i osvojio nekoliko nagrada za pitching.
                  </p>
                </div>
              </div>

              <div className='jury__jurors-single-no-image'>
                <div>
                  <p>
                    <strong>Nikola Spasić</strong> je nagrađivani srpski reditelj, montažer i producent. Diplomirao je režiju na AUNS i masterirao montažu na FDU, nakon čega je upisao doktorske studije iz oblasti dramskih umetnosti na AUNS. Njegovi filmovi prikazivani su na značajnim međunarodnim filmskim festivalima poput Torino Film Festival, FID Marseille, Seville European Film Festival, Dok.Leipzig... Njegov dugometražni igrani film „Kristina“ osvojio je First Film Award na FID Marseille-u i Nagradu za najboljeg reditelja u usponu na Seville European Film Festival-u, kao i desetak drugih međunarodnih nagrada i priznanja.
                  </p>
                </div>
              </div>

              <div className='jury__jurors-single-no-image'>
                <div>
                  <p>
                    <strong>Vanja Nikolić</strong> rođen je 1993. na dalekom i hladnom severu Hrvatske. Nakon uspešno završene gimnazije, upisuje novinarstvo, ali ipak kreće put filmske režije. Snima svoj kratkometražni prvenac „Minute“ 2018, nakon toga, 2021, radi svoj drugi kratkometražni film, „Kaže se Čakovec“ koji nailazi na oduševljenje publike i kritike. Debituje 2024. i u dokumentarnom žanru, filmom „Gitak TV - Prvi 20 godina“. Trenutno razvija svoj debitantski dugometražni film, za koji se nada da će ga snimiti 2025. godine.
                  </p>
                </div>
              </div>
            </div>


            <div className='jury__text'>
              <p>
                <strong>Studentski žiri Međunarodne selekcije</strong><br /><br />
              </p>


              <div className='jury__jurors-single-no-image'>
                <div>
                  <p>
                    <strong>Vanja Platiša</strong> (Montaža u audiovizuelnim medijima) bavi se montažom jer je od malena sklona stvaranju celine od sitnih detalja. U slobodno vreme voli da fotka, putuje i istražuje različite mesta, i upoznaje nove ljude.
                  </p>
                </div>
              </div>

              <div className='jury__jurors-single-no-image'>
                <div>
                  <p>
                    <strong>Ema Bogosavljević</strong> (Produkcija za audio vizuelne medije) film zapravo nije bila njena prva ljubav, to je bilo pozorište i sama scena na kojoj je odrasla kao etno pevačica. Kako je došla korona i samoizolacija, film je oživela kao prekoneophodnu zabavu i odlučila je da se bavi filmom.
                  </p>
                </div>
              </div>

              <div className='jury__jurors-single-no-image'>
                <div>
                  <p>
                    <strong>Dragana Đokić</strong> (Dramaturgija) uvek je možete pronaći u njenoj sobi, pretvorenoj u biblioteku od hiljadu naslova, kako čita o slovenskoj mitologiji. Ako ne piše, onda gleda trilere, anime serije i guilty pleasure sitkome. Često hvata Pokemone na Nintendu.
                  </p>
                </div>
              </div>

              <div className='jury__jurors-single-no-image'>
                <div>
                  <p>
                    <strong>Vanja Bojović</strong> (Kamera) za nju je snimanje umetnost zapažanja. Voli da putuje, da ide u bioskop, pozorište i da posećuje muzeje, raduje se jutarnjoj kafi, šetnjama u prirodi, dobroj muzici, životinjama i dragim ljudima. Film koji ju je podsetio koliko treba da uživamo u svakodnevnim stvarima i da živimo u trenutku jeste film "Perfect Days" koji svima toplo preporučuje.
                  </p>
                </div>
              </div>

              <div className='jury__jurors-single-no-image'>
                <div>
                  <p>
                    <strong>Uglješa Ranisavljević</strong> (Multimedijalna režija) omiljeni film mu je „Čuvar plaže u zimskom periodu“. Taj film mu je važan iz mnogo razloga. Pogledao ga je „milion“ puta i iznova i iznova mu se vraća.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
