import React from 'react'
import { Helmet } from 'react-helmet-async';

import HeroComponent from '../components/HeroComponent'

import './WorkshopMaskNews.scss'

export default function WorkshopMaskNews() {

  const images = {
    mobile: "/assets/images/news-workshop-mask.png",
    desktop: "/assets/images/news-workshop-mask-desktop.png"
  }

  return (
    <div className="volonter">
      <Helmet>
        <title>Prijavi se na radionicu SXF maske</title>
      </Helmet>
      <HeroComponent title="Prijavi se na radionicu SXF maske" images={images}/>
      <div className="content-wrapper">
        <div className="volonter__wrapper">
          <p>U okviru 20. izdanja Filmskog Fronta, koje će se održati u periodu od 27. do 30. oktobra u Kulturnom centru Novog Sada i Kreativnom distriktu, organizovana je višednevna <strong>radionica izrade SXF maske.</strong></p>
          <p>Predavač je <strong>Miroslav Lakobrija</strong>, diplomirani akademski vajar i stručnjak za specijalne filmske efekte. Redovan je profesor na akademiji za estetiku i kozmetologiju Purity, na predmetu Filmska maska i specijalni efekti. Godine 1997. sa Jakšom Vlahovićem, osnovao je udruženje „Mladi kadrovi”, te krenuo u ostvarenje ljubavi prema specijalnim filmskim efektima. Jedan je od autora i osnivača Festivala srpskog filma fantastike. Profesionalno se bavi izradom maski i make-up specijalnim efektima za potrebe snimanja filmova. Osim rada na filmu, oprobao se i u TV serijama, kao i u pozorištu. Proteklih godina je vodio više umetničkih radionica za maske. Godine 2011. dobio je nagradu za najbolje specijalne efekte, za rad na filmu „Zona mrtvih“, nagradu ’„Kaplja“ za filmski doprinos horor žanru – Ruska međunarodna nagrada za horor film 2018 godine. Dobitnik je nagrada „Zlatna arena“ u Puli za rad na filmu „Poslednji Srbin u Hrvatskoj“ 2019. godine. Najpoznatiji projekti na kojima je radio: „Život i smrt porno bande“, „Zona mrtvih“ , „Mamula“, „Srpski film“, Sveti Georgije ubiva aždahu “ i „Kalkanski krugovi”..</p>
          <p><strong>Radionica je podeljena u četiri segmenta:</strong></p>
          <ol className="volonter__list">
            <li>Upoznavanje sa materijalima, modelovanje u glini i kalupiranje (27. oktobar)</li>
            <li>Izlivanje silikonskih rana, ožiljaka i maski i tehnika šminkanja do stvaranja finalne maske (28. oktobar)</li>
            <li>Prezentacija maski (29. oktobar)</li>
            <li>Zombi šetnja (30. oktobar)</li>
          </ol>
          <p>Za učešće u radionici, prethodno profesionalno iskustvo ili formalno obrazovanje nije neophodno. Prijave su otvorene do 23. oktobra 2022. godine, a formular se nalazi na <a href="https://docs.google.com/forms/d/e/1FAIpQLSfP3aBRrW74C9_5az5HbcYVAob0IfBhe9lO3SHUo8mablHHYA/viewform?pli=1&pli=1" rel="noreferrer" target="_blank" >LINKU</a>.</p>
        </div>
      </div>
    </div>
  )
}
