import React, { useState, useEffect } from 'react'
import LangSwitcher from './LangSwitcher'
import { Link, NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from '../svg-images/logo.svg';
import { ReactComponent as Facebook } from '../svg-images/facebook.svg';
import { ReactComponent as Instagram } from '../svg-images/instagram.svg';
import { ReactComponent as Youtube } from '../svg-images/youtube.svg';

import { useTranslation } from "react-i18next";
import { useCurrentWidth } from '../hooks/useResize'

import './Nav.scss'

export default function Nav() {

  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const links = t('routes', { returnObjects: true })

  // const navSubmitButton = t('navSubmitButton', { returnObjects: true })

  const language = t('lang');

  const windowWidth = useCurrentWidth()

  const routesSr = [
    "Vesti",
    "O frontu",
    "Program",
    "Galerija",
    "Sponzori"
  ]

  const routsEn = [
    "News",
    "About",
    "Program",
    "Gallery",
    "Sponsors"
  ]

  useEffect(() => {
    if (isOpen)
      document.body.classList.add('no-scroll')
    else
      document.body.classList.remove('no-scroll')
  }, [isOpen])

  return (
    <div className="nav">
      <div className="nav__header">
        {/* <div className="nav__lower">
        </div> */}
        <div className="nav__lower-links">
          <Link to="/">
            <Logo className={`nav__logo`} onClick={() => {
              if (windowWidth < 1024 && isOpen) setIsOpen(false)
            }}
            />
          </Link>
          <button className={`menu ${isOpen ? 'opened' : ''}`} onClick={() => setIsOpen(isOpen => !isOpen)}>
            <svg width="100" viewBox="0 0 100 100">
              <path className={`line line1`} d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
              <path className={`line line2`} d="M 20,50 H 80" />
              <path className={`line line3`} d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
            </svg>
          </button>
          {/*<a target="_blank" rel="noreferrer" href="https://filmfreeway.com/festivalFilmFront" className="nav__submit">
            {navSubmitButton.text}
          </a>*/}
          <div className="nav__links">
            {
              links.map((link, index) => {
                return (
                  <div onClick={() => windowWidth < 1024 && setIsOpen(!isOpen)} key={index}>
                    <NavLink activeClassName='nav__links-link--active' to={link} className={`nav__links-link`}>
                      {language === 'sr' ? routesSr[index] : routsEn[index]}
                    </NavLink>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="nav__last-items">
          <div className="nav__socials">
            <a href="https://www.facebook.com/filmskifront" target="_blank" rel="noreferrer"><Facebook /></a>
            <a href="https://www.instagram.com/filmskifront/" target="_blank" rel="noreferrer"><Instagram /></a>
            <a href="https://www.youtube.com/c/FilmskiFront" target="_blank" rel="noreferrer"><Youtube /></a>
          </div>
          <div className="nav__lang">
            <LangSwitcher />
          </div>
        </div>
        {/* <div className="nav__upper">
        </div> */}
      </div>
      <div className="nav__overlay" style={{ left: !isOpen ? '-100%' : '0', backgroundImage: 'url(/assets/images/homepage-background.webp)' }}>
        <LangSwitcher />
        {/*<a onClick={() => setIsOpen(!isOpen)} target="_blank" rel="noreferrer" href="https://filmfreeway.com/festivalFilmFront" className="nav__overlay-submit">
          <div className="nav__overlay-submit-button">{navSubmitButton.text}</div>
        </a>*/}
        <div className="nav__overlay-links">
          {
            links.map((link, index) => {
              return (
                <div onClick={() => setIsOpen(!isOpen)} key={index} style={{ textAlign: 'center' }}>
                  <NavLink activeClassName='nav__overlay-link--active' to={link} className="nav__overlay-link">
                    {language === 'sr' ? routesSr[index] : routsEn[index]}
                  </NavLink>
                </div>
              )
            })
          }
        </div>
        <div className="nav__overlay-socials">
          <a href="https://www.facebook.com/filmskifront" target="_blank" rel="noreferrer"><Facebook /></a>
          <a href="https://www.instagram.com/filmskifront/" target="_blank" rel="noreferrer"><Instagram /></a>
          <a href="https://www.youtube.com/c/FilmskiFront" target="_blank" rel="noreferrer"><Youtube /></a>
        </div>
      </div>
    </div>
  )
}
