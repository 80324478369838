import React from 'react'

import { Helmet } from 'react-helmet-async';

import HeroComponent from '../components/HeroComponent'

import "./Masterclass.scss"

export default function MasterclassNews() {

  const images = {
    mobile: '/assets/images/news-masterclass-mob.png',
    desktop: '/assets/images/news-mastercass.png'
  }

  return (
    <div>
      <Helmet>
        <title>Masterclass na Frontu: "Kako i zašto pisati scenario za ne-igrane forme?</title>
      </Helmet>

      <HeroComponent
        title={`Masterclass na Frontu: "Kako i zašto pisati scenario za ne-igrane forme?"`}
        images={images}
      />

      <div className='masterclass'>
        <div className='content-wrapper'>
          <div className='masterclass__text'>
            <p>U okviru pratećeg programa festivala Filmski Front održaće se masterclass program reditelja Luke Papića pod nazivom "Kako i zašto pisati scenario za ne-igrane forme?" Na ovom predavanju reditelj Luka Papić ću uz prikazivanje inserata iz  svojih filmova, pokazati šta je pisalo na papiru, a šta je na kraju završilo na platnu. Kroz uspešne i neuspešne primere, pokazaće bitnost scenarija u ne-igranom filmu, ali i da se sam scenario "ne piše na terenu". Masterclass program će se održati u subotu 28. oktobra u 16.00 časova u bioskopskoj sali Kulturnog centra Novog Sada. Kako biste učestvovali u programu, neophodno je da se prijavite putem mail adrese <a href="mailto:kinoklubnovisad@gmail.com" rel="noreferrer" target="_blank">kinoklubnovisad@gmail.com.</a><br /><br /> Luka Papić je filmsku režiju studirao na akademiji umetnosti HFBK u Hamburgu, gde je završio Master studije u klasi Prof. Angele Šanelek 2016. godine. Njegov docu-fiction BEZ, 63 min. 2022. je nagrađen na festivalu Visions du Reel u Nionu dobio nagradu žirija za najbolji film u konkurenciji srednjeg metra, Grand prix na Beldocs festivalu u Beogradu i specijalna priznanja na beogradskom Martovskom festivalu Underhill-u u Podgorici. Drugi dugi dokumentarni film „Prizvan i pozvan“, koji je realizovao ko-autorski sa Srđom Vučom, premijeru je imao u Envision takmičarskom programu IDFA festivala u Amsterdamu, takođe 2022. godine. film je osvojio nagradu publike za najbolji evropski film, kao i nagradu za najbolju montažu na festivalu Beldocs u Beogradu, 2023. dok je na festivalu Underhill u Podgorici dobio specijalno priznanje.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
