import React from 'react'
import Carousel from 'react-gallery-carousel';
import { Helmet } from 'react-helmet-async'

import { useTranslation } from "react-i18next"

import './Gallery.scss'
import 'react-gallery-carousel/dist/index.css';

export default function Gallery() {

  const { t } = useTranslation();

  const content = t("gallery")

  const images = () => {
    let imageArr = []
    for (let i = 11; i >= 1; i--) {
      imageArr.push({ src: `/assets/images/gallery/${i}.jpg` })
    }
    return imageArr
  }

  return (
    <div className="gallery">
      <Helmet>
        <title>{content}</title>
      </Helmet>
      <div className="content-wrapper">
        <Carousel images={images()} className="gallery__slider" />
      </div>
    </div>
  )
}
