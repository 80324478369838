import React from 'react'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from "react-i18next";

import { Link } from 'react-router-dom'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import './News.scss'

export default function News() {

  const { t } = useTranslation();

  const content = t("news", { returnObjects: true })

  return (
    <div className="news">
      <Helmet>
        <title>{content.newsPageTitle}</title>
      </Helmet>
      <div className="content-wrapper">
        <h1 className="news__title">{content.newsTitle}</h1>
        <div className="news__archive">
          {
            content.newsArchive && content.newsArchive.map((news, index) => {
              return (
                <Link to={news.link} key={index}>
                  <div className="news__archive-wrapper">
                    <LazyLoadImage effect="blur" src={news.image} alt="news" />
                    <h2>{news.title}</h2>
                    <p>{news.date}</p>
                  </div>
                </Link>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
