import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async'

import HeroComponent from '../components/HeroComponent'

import "./JuryNews.scss"

export default function JuryNews() {

  const images = {
    mobile: '/assets/images/news-jury.png',
    desktop: '/assets/images/news-jury-desktop.png'
  }

  return (
    <div>
      <Helmet>
        <title>Predstavljamo vam ovogodišnji stručni žiri Filmskog Fronta</title>
      </Helmet>
      <HeroComponent title="Predstavljamo vam ovogodišnji stručni žiri Filmskog Fronta" images={images} />

      <div className='jury'>
        <div className='content-wrapper'>
          <div className='jury__text'>
            <p>21. Međunarodni festival kratkometražnog igranog filma Filmski Front održava se od 26. do 29. oktobra u Kulturnom centru Novog Sada. Odlukom stručnog žirija, u saradnji sa Filmskim centrom Srbije, najboljem filmu u selekciji Srbija/region dodeljuje se nagrada u iznosu od 100.000 dinara. Članovi i članica ovogodišnjeg stručnog žirija su reditelji: Mina Đukić, Luka Papić i Siniša Cvetić.</p>
          </div>

          <div className='jury__jurors'>
            <div className='jury__jurors-single'>
              <LazyLoadImage effect="blur" src={'/assets/images/jury-mina.png'} alt="Mina Djukic" />
              <div>
                <p>Mina Đukić diplomirala je na Fakultetu Dramskih Umetnosti, a radi kao gostujući predavač na predmetu “Digital Filmmaking” na SAE INSTITUTU u Beogradu.</p>
                <p> Režirala je višestruko nagrađivani film Neposlušni (2014), koji je nakon svetske premijere na festivalu Sundance i evropske premijere na festivalu u Roterdamu, na preko 15 internacionalnih festivala osvojio 10 nagrada.</p>
                <p>Prestižni magazin Screen u svom prikazu film je nazvao ‘’divnim’’ i ‘’bujnom lirskom pričom’’, a posebno je pohvalio snažne emocije i fotografiju. Pored toga, Mina je u okviru Filmske kuće Kiselo dete, čiji je osnivač, producirala filmove Tilva Roš (2010/ Locarno IFF, Sarajevo FF) NIkole Ležaića, Kamen u ruci (2017 / Tampere, ACID) Stefana Ivančića i kratkometražni dokumentarni film Yugo, kratka autobiografija (2010), čiji je i reditelj. Koproducirala je filmove Igla ispod praga (2016, Sarajevo FF) Ivana Marinovića i Sloboda ili smrt (2018) Vladimira Blaževskog.</p>
                <p>Tokom 2021. režirala 6 epizoda serije “Mama i tata se igraju rata 2” Scenarista je jedne priče serije “Poseta” pod nazivom “Požar”, i reditelj druge priče pod nazivom “Plen”. Serija je trenutno u fazi post-produkcije.</p>
              </div>
            </div>
            <div className='jury__jurors-single'>
              <LazyLoadImage effect="blur" src={'/assets/images/jury-luka.png'} alt="Mina Djukic" />
              <div>
                <p>Luka Papić filmsku režiju studirao je na akademiji umetnosti HFBK u Hamburgu, gde je završio Master studije u klasi Prof. Angele Šanelek 2016. godine.</p>
                <p>Njegov docu-fiction BEZ, 63 min. 2022. je nagrađen na festivalu Visions du Reel u Nionu gde je dobio nagradu žirija za najbolji film u konkurenciji srednjeg metra, Grand prix na Beldocs festivalu u Beogradu i specijalna priznanja na beogradskom Martovskom festivalu Underhill-u u Podgorici.</p>
                <p>Drugi dugi dokumentarni film „Prizvan i pozvan“, koji je realizovao ko-autorski sa Srđom Vučom, premijeru je imao u Envision takmičarskom programu IDFA festivala u Amsterdamu, takođe 2022. godine. Film je osvojio nagradu publike za najbolji evropski film, kao i nagradu za najbolju montažu na festivalu Beldocs u Beogradu, 2023. dok je na festivalu Underhill u Podgorici dobio specijalno priznanje.</p>
              </div>
            </div>
            <div className='jury__jurors-single'>
              <LazyLoadImage effect="blur" src={'/assets/images/jury-sinisa.png'} alt="Mina Djukic" />
              <div>
                <p>Siniša Cvetić od 2013. do 2016. studira filozofiju na Filozofskom fakultetu, Univerziteta u Beogradu. Od 2016. godine studira filmsku i TV režiju na Fakultetu dramskih umetnosti u Beogradu.</p>
                <p>Tokom studija je režirao nekoliko kratkih filmova od kojih su neki bili prikazivani i nagrađivani na festivalima (Kompromis, Nemi Krik, Dinosaur, Izvršenje). U saradnji sa producentskom kućom Košutnjak Film i uz podršku Filmskog Centra Srbije snimio je svoj debitantski film Usekovanje, koji je imao internacionalnu premijeru u takmičarskoj selekciji Međunarodnog filmskog festivala u Moskvi, gde je osvojio nagradu Srebrni Đorđe za najboljeg reditelja i nagradu publike.</p>
                <p>Film je prikazan na više stranih i domaćih festivala, kako u takmičarskim tako i u revijalnim programima i osvoijo preko deset nagrada. Bioskopska premijera za njegov drugi dugometražni film Jorgovani je planirana za novembar 2023. godine, a u predprodukciji je i treći dugometražni film Hajduci koji je takođe podržan od strane Filmskog centra Srbije. U među vremenu je režirao i dokumentarni film Film davne budućnosti u produkciji kuće Magična linija.</p>
                <p>U saradnji sa dramskom spisateljicom Majom Pelević priprema postavku originalnog postdramskog komada o Edvardu Snoudenu, sistemima nadziranja u 21. veku i veštačkoj inteligenciji u čuvenom pozorištu Teatar Nacija u Moskvi. Premijera predstave je predviđena na proleće 2024 godine.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
