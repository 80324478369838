import React from 'react'
import {useCurrentWidth} from '../hooks/useResize'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import './HeroComponent.scss'

export default function HeroComponent({title, images}) {

  const windowWidth = useCurrentWidth()

  return (
    <div className="hero">
      <div className="content-wrapper">
        <div className="hero__container">
          <h1 className="hero__title">{title}</h1>
          <LazyLoadImage effect="blur" className="hero__image" src={windowWidth < 1024 ? images.mobile : images.desktop} alt="hero"/>
        </div>
      </div>
    </div>
  )
}
