import React from 'react'
import { Helmet } from 'react-helmet-async'

import HeroComponent from '../components/HeroComponent'

export default function KristinaNews() {

  const images = {
    mobile: '/assets/images/kristina.png',
    desktop: '/assets/images/kristina-desktop.png'
  }

  return (
    <div className="zombie">
      <Helmet>
        <title>NOVOSADSKA PREMIJERA NAGRAĐIVANOG FILMA „KRISTINA“</title>
      </Helmet>
      <HeroComponent title="NOVOSADSKA PREMIJERA NAGRAĐIVANOG FILMA „KRISTINA“" images={images} />

      <div className="content-wrapper">
        <p className="zombie__text">Nakon osvojenih nagrada na festivalima u Marseju i Sevilji, film „Kristina“ stiže u Novi Sad. Novosadska premijera domaćeg dugometražnog ostvarenja održaće se 8. decembra u 19.00 časova u bioskopskoj sali Kulturnog centra Novog Sada u organizaciji Međunarodnog festivala kratkometražnog igranog filma Filmski Front.</p>
        <br />
        <p className="zombie__text">Film koji je neverovatnom brzinom zadobio pažnju svetske javnosti zasnovan je na stvarnoj ličnosti koja u filmu glumi samu sebe, a sinopsis nam otkriva da je Kristina transrodna seks radnica koja živi sa mačkom, sakuplja antikvitete i odlazi na seanse nehipnotičke regresije. Posle jedne seanse, upoznaje Marka i u tom trenutku se rađa platonska ljubav između ovo dvoje ljudi čiji slučajni susreti tek predstoje. Glavnu ulogu u filmu tumači Kristina Milosavljević, reditelj je Nikola Spasić, a scenaristkinja filma Milanka Gvoić. Svetsku premijeru film „Kristina” je imao na čuvenom festivalu FID u Marseju u julu ove godine na kom je osvojio Grand Prix. Na Festivalu evropskog filma u Sevilji, reditelju Nikoli Spasiću pripala je nagrada za najboljeg reditelja prvog ili drugog filma. U pitanju je jedan od najcenjenijih festivala u Evropi koji je poznat po tome što se svake godine na festivalu objavljuju nominacije za EFA nagradu, poznatu i kao evropski Oskar. „Kristina“ se trenutno paralelno prikazuje na nekoliko festivala u Italiji i Španiji, a nedavno je imala domaću premijeru na Festivalu autorskog filma. Publika Novog Sada 8. decembra ima priliku da među prvima pogleda ostvarenje čija je budućnost na evropskoj i svetskoj sceni na pomolu. Ulaznice su u prodaji na Gigstix prodajnim mestima i putem sajta gigstix.com po ceni od 250 dinara.</p>
      </div>
    </div>
  )
}
