import React from 'react'

import { Helmet } from 'react-helmet-async';

import HeroComponent from '../components/HeroComponent'

import "./Masterclass.scss"


export default function Masterclass22News() {

  const images = {
    mobile: '/assets/images/news-masterclass-22-mob.png',
    desktop: '/assets/images/news-masterclass-22.png'
  }


  return (
    <div>
      <Helmet>
        <title>OFF Novi Sad i Filmski Front predstavljaju: Masterclass "Sinteza dokumentarnog i igranog"</title>
      </Helmet>

      <HeroComponent
        title={`OFF Novi Sad i Filmski Front predstavljaju: Masterclass "Sinteza dokumentarnog i igranog"`}
        images={images}
      />

      <div className='masterclass'>
        <div className='content-wrapper'>
          <div className='masterclass__text'>
            <p>
              U okviru pratećeg programa festivala Filmski Front održaće se masterclass program reditelja Nikole Spasića pod nazivom "Sinteza dokumentarnog i igranog". Na ovom predavanju reditelj Nikola Spasić će uz prikazivanje inserata iz svojih filmova, objasniti kako je dolazio do rešenja, odakle su dolazili uticaji, kao i koje je izazove imao u konstruisanju, ali i finansiranju i plasmanu svojih dokumentarnih dela. Masterclass program će se održati u nedelju, 27. oktobra 2024. u 15.00 časova u bioskopskoj sali Kulturnog centra Novog Sada. Kako biste učestvovali u programu, neophodno je da se prijavite putem mail adrese <a href="mailto:offnovisad@gmail.com">offnovisad@gmail.com</a>.<br /><br />

              Nikola Spasić je nagrađivani srpski reditelj, montažer i producent. Diplomirao je režiju na AUNS i masterirao montažu na FDU, nakon čega je upisao doktorske studije iz oblasti dramskih umetnosti na AUNS. Vanredni je profesor na RAF-u Univerziteta Union u Beogradu. Njegovi filmovi prikazivani su na značajnim međunarodnim filmskim festivalima poput Torino Film Festival, FID Marseille, Seville European Film Festival, Dok.Leipzig, Jihlava IDFF, i dr. Njegov dugometražni igrani film "Kristina" osvojio je First Film Award na FID Marseille-u i Nagradu za najboljeg reditelja u usponu na Seville European Film Festival-u, kao i desetak drugih međunarodnih nagrada i priznanja. Bio je kandidat za prestižnu panevropsku DocAlliance nagradu. Dokumentarni film "Zašto je Dragan napravio orkestar" prikazan je na preko 40 festivala, osvojivši Grand Prix i Nagradu publike na Cinema City IFF. Njegovi filmovi prikazani su na brojnim evropskim televizijskim kanalima i VOD platformama. Su-osnivač je novosadske produkcijske kuće Rezon. Selektor je glavnog međunarodnog takmičarskog programa Novi Sad Film Festivala, kao i član saveta nekoliko filmskih i pozorišnih festivala. Član je Asocijacije reditelja, Udruženja DokSrbija i Udruženja Filmskih Producenata Srbije.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
