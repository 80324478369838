import React from 'react'
import { Helmet } from 'react-helmet-async';

import HeroComponent from '../components/HeroComponent'

export default function Volontiers21News() {

  const images = {
    mobile: '/assets/images/news-volontiraj-21-ff-mob.png',
    desktop: '/assets/images/news-volontiraj-21-ff.png'
  }

  return (
    <div>
      <Helmet>
        <title>Stekni iskustvo u organizaciji festivala – VOLONTIRAJ na 21. Filmskom Frontu</title>
      </Helmet>

      <HeroComponent
        title="Stekni iskustvo u organizaciji festivala – VOLONTIRAJ na 21. Filmskom Frontu"
        images={images}
      />

      <div>
        <div className="content-wrapper">
          <div className="volontiers">
            <p>Ukoliko želiš da stekneš iskustvo u organizaciji festivala i upoznaš se sa filmskom industrijom, budi deo volonterskog tima 21. Međunarodnog festivala kratkometražnog igranog filma Filmski Front, koji će se održati periodu od 26. do 29. oktobra u Kulturnom centru Novog Sada.</p>
            <p><br />Da bi postao/la volonter/ka, neophodno je da se prijaviš do 21. oktobra, tako što ćeš na mejl <a href="mailto:kinoklubnovisad@gmail.com" rel="noreferrer" target="_blank">kinoklubnovisad@gmail.com</a> poslati:</p>
            <ol>
              <li>Ime i prezime</li>
              <li>Godište</li>
              <li>Stečeno obrazovanje</li>
              <li>Kontakt mejl / telefon</li>
              <li>Ukratko navedeš svoja interesovanja, kao i koja su tvoja prethodna volonterska iskustva<br /><span className="volontiers__need-break-here">(ukoliko ih poseduješ).</span></li>
              <li>Navedeš za koju volontersku poziciju konkurišeš:
                <ul>
                  <li>Logistika - nameštanje stolica, sprovođenje publike i prebrojavanje i sakupljanje glasova publike</li>
                  <li>Promocija - deljenje programskih knjižica, dočekivanje publike i anketiranje</li>
                  <li>Press - snimanje i fotografisanje</li>
                </ul>
              </li>
            </ol>
            <p>Uz to, potrebno je da:</p>
            <ol>
              <li>Imaš 18 ili više godina</li>
              <li>Budeš u Novom Sadu u periodu od 26. do 29. oktobra 2023. godine</li>
              <li>Imaš obezbeđen smeštaj u Novom Sadu u naznačenom periodu</li>
            </ol>
            <p><br />Nakon razmatranja pristiglih prijava bićeš obavešten/a o rezultatima konkursa.<br />Front čeka pojačanje!</p>
          </div>
        </div>
      </div>
    </div>
  )
}
