import React from 'react'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from "react-i18next";

import 'react-lazy-load-image-component/src/effects/blur.css';

import './Home.scss'

export default function Home() {

  const { t } = useTranslation();

  const lang = t('lang')


  return (
    <div
      className="home"
      style={{ backgroundImage: 'url(/assets/images/homepage-background.webp)' }}
    >
      <Helmet>
        <title>Film Front</title>
      </Helmet>

      <div className="home__hero">

        <img
          src={lang === 'sr' ? '/assets/images/homepage-background-mob.png' : '/assets/images/homepage-background-mob-en.png'}
          className="home__hero-image-mobile"
          alt="Film front"
        />


        <img
          src={lang === 'sr' ? '/assets/images/homepage-desktop.png' : '/assets/images/homepage-desktop-en.png'}
          className="home__hero-image-desktop"
          alt="Film front"
        />
      </div>

      {/*<a className="home__button" target="_blank" rel="noreferrer" href="https://filmfreeway.com/festivalFilmFront">
        <img src="/assets/images/homepage-button.png" alt="submit" />
      </a>*/}
    </div>
  )
}
