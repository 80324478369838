import React from 'react'
import HeroComponent from '../components/HeroComponent'
import { Helmet } from 'react-helmet-async'

import './AwardsNews.scss'

export default function AwardsNews() {

  const images = {
    mobile: '/assets/images/news-awards.png',
    desktop: '/assets/images/ziri-desktop.png'
  }

  return (
    <div className="awards">
      <Helmet>
        <title>DODELOM NAGRADA ZAVRŠEN 20. FILMSKI FRONT</title>
      </Helmet>
      <HeroComponent images={images} title="DODELOM NAGRADA ZAVRŠEN 20. FILMSKI FRONT"/>
      <div className="content-wrapper">
        <p className="zombie__text">
          Jubilarni 20. Međunarodni festival kratkometražnog igranog filma Filmski Front održan je u periodu od 27. do 30. oktobra u Kulturnom centru Novog Sada i Kreativnom distriktu. Ova manifestacija, koja već 20 godina u Novom Sadu neguje autorski izraz i afirmiše nezavisne produkcije, i ove godine uspela je da privuče pažnju velikog broja gledalaca. Tokom četiri dana festivala, preko 3000 ljudi prisustvovalo je projekcijama u Kulturnom centru Novog Sada, kao i propratnim programima u Kreativnom distriktu.
        </p>
        <p className="zombie__text">
          Poslednjeg dana festivala proglašeni su pobednici u dve kategorije. U Srbija/region selekciji, odlukom stručnog žirija, nagrada za najbolji film pripala je ostvarenju „Nije zima za komarce“, reditelja Josipa Lukića i Klare Šovagović. Najboljem filmu u ovoj selekciji, godinama unazad u saradnji sa Filmskim centrom Srbije dodeljuje se novčana nagrada u iznosu od 100.000 dinara. „Bilo bi suvišno pisati komplikovano obrazloženje za film čiji kvalitet proizilazi iz jednostavnosti, jasne autorske namere, distinktivnog humora i pažljivo dozirane melanholije. Zaokružen, inteligentan i slojevit film“, naveo je žiri.
        </p>
        <p className="zombie__text">
          Pored toga, tročlani stručni žiri koji su činili reditelji Marko Đorđević i Goran Nikolić i scenaristkinja Maša Seničić, Specijalno priznanje dodelio je filmu „Krotki“, reditelja Jovana Dimoskog, uz obrazloženje:
        </p>
        <p className="zombie__text">
          „Sirov i emotivan film o odrastanju, sa pažljivo napisanim junacima koje tumače izuzetni i sjajno režirani mladi glumci.“
        </p>
        <p className="zombie__text">
          U Međunarodnoj konkurenciji, odlukom publike, ubedljivu pobedu odneo je film „Get Home Safe“ rediteljke Tamare Denić. Ovogodišnja festivalska novina bio je studentski žiri, koji je u Međunarodnoj selekciji nagradu za najbolji film dodelio ostvarenju „Work it Class!“ reditelja Pola Diglera, dok je specijalno priznanje studentskog žirija pripalo filmu „Radio Silence“ rediteljke Kerren Lumer-Klabbers.
        </p>
        <p className="zombie__text">
          Filmski Front svih ovih godina nema ulogu samo u popularizaciji kratkog metra, već i u edukaciji mladih koji su se opredelili za stvaralaštvo na filmu. U tom duhu, u nedelju je održana i prva novosadska zombi šetnja u Kreativnom distriktu, na kojoj su polaznici Frontove radionice SFX maske imali priliku da šminkaju stotine posetilaca uz asistenciju profesionalnog tima, na čelu sa Miroslavom Lakobrijom, stručnjakom za specijalne filmske efekte. Kao deo programa 20. izdanja festivala, mladi ljudi imali su priliku da budu deo radionice režije koju je držao reditelj Danilo Stanimirović, kao i da prisustvuju panel diskusijama.
        </p>
      </div>
    </div>
  )
}
