import React from 'react'
import { Helmet } from 'react-helmet-async'

import HeroComponent from '../components/HeroComponent'

import "./OfficialSelection21News.scss";

export default function OfficialSelection22News() {

  const images = {
    mobile: '/assets/images/news-official-selection-22.png',
    desktop: '/assets/images/news-official-selection-22-desktop.webp'
  }

  return (
    <div className='official-selection-21'>
      <Helmet>
        <title>Zvanična selekcija 22. Filmskog Fronta</title>
      </Helmet>

      <HeroComponent title="Zvanična selekcija 22. Filmskog Fronta" images={images} />

      <div className='official-selection-21__content'>
        <div className='content-wrapper'>
          <h3 className='official-selection-21__content-title'>Međunarodna selekcija</h3>
          <p className='official-selection-21__content-text'>
            A Summer's End Poem, r. Lam Can-zhao (2024, Kina, 15’)<br /><br />
            Craftwork, r. David Pérez Sañudo (2023, Španija, 20’)<br /><br />
            Shalal, r. Amir Ali Sisipour (2023, Iran, 14’)<br /><br />
            Three Women Named Svetlana, r. Natalia Boorsma (2024, Holandija, 15’)<br /><br />
            Sweatshop Girl, r. Selma Cervantes (2023, Meksiko, 17’)<br /><br />
            Abel, r. Dekel Berenson, Paul Wesley (2024, SAD, 15’)<br /><br />
            3MWh, r. Marie-Magdalena Kochová (2024, Češka Republika, 12’)<br /><br />
            Recurrence, r. Ali Alizadeh (2023, Iran, 15’)<br /><br />
            Hooks, r. Charles Parisé (2023, Kanada, 9’)<br /><br />
            New, r. Zhenia Kazankina (2024, Rusija, 20’)<br /><br />
            Trains Passing, r. Ani Mrelashvili (2023, Nemačka, 10’)<br /><br />
            Headless Horsemen, r. Ovsanna Gevorgyan (2023, Jermenija, 15’)<br /><br />
            Two Worlds, r. Marianna Sciveres (2023, Italija, 14’)<br /><br />
            Balkan, Baby, r. Boris Gavrilović (2023, Nemačka, 19’)<br /><br />
            Cycle, r. Naz Çaybaşı (2023, Turska, 11’)<br /><br />
            Everybody’s Gotta Love Sometimes, r. Sein Lyan Tun (2023, Francuska, 15’)<br /><br />
            Hypnosis, r. Anna Simeonoff (2023, Bugarska, 1’)<br /><br />
            An Odd Turn, r. Francisco Lezama (2024, Argentina, 22’)<br /><br />
            Undress Me, r. Florent Médina, Maxime Vaudano (2023, Francuska, 16’)<br /><br />
            A Tin of White, r. Sara Stijović (2023, Velika Britanija, 11’)<br /><br />
          </p>

          <h3 className='official-selection-21__content-title'>Srbija/region selekcija</h3>
          <p className='official-selection-21__content-text'>
            Čovjek koji nije mogao šutjeti, r. Nebojša Slijepčević (2024, Hrvatska, 13’)<br /><br />
            Duck Roast, r. Jelica Jerinić (2023, Srbija/Finska, 13’)<br /><br />
            Bele rade, r. Gvozden Ilić (2024, Srbija, 20’)<br /><br />
            Morje med nama, r. Lun Sevnik (2024, Slovenija, 15’)<br /><br />
            Čopor, r. Marko Jukić (2023, Hrvatska, 23’)<br /><br />
            Presing, r. Milica Vujasin (2024, Bosna i Hercegovina, 9’)<br /><br />
            Duhovi na mojim leđima, r. Nikola Stojanović (2023, Srbija, 15’)<br /><br />
            Jogurt, sok, cigarete, r. Josip Lukić (2024, Hrvatska, 25’)<br /><br />
            Neka cvjeta rosno cvijeće, r. Ivan Bakrač (2023, Crna Gora, 17’)<br /><br />
            Šala, r. Trifun Sitnikovski (2024, Severna Makedonija, 21’)<br /><br />
            Ležeći na suncu, biti u kurcu, r. Veljko Petrović (2024, Srbija, 18’)<br /><br />
            Ja ću prva, r. Martina Marasović (2023, Hrvatska, 22’)<br /><br />
            Bull's Eye, r. Petar Lakić (2024, Srbija, 17’)<br /><br />
            Utrinek, r. Oskar Šubić (2023, Slovenija, 7’)<br /><br />
            A šta ako nikada ne dođe, r. Đorđije Petrović (2024, Srbija, 12’)<br /><br />
            Mrtvi čvor, r. Ismira Mašić (2023, Bosna i Hercegovina, 18’)<br /><br />
            Mali val, r. Filip Peruzović (2023, Hrvatska, 19’)<br /><br />
          </p>

          <h3 className='official-selection-21__content-title'>HororFront selekcija</h3>
          <p className='official-selection-21__content-text'>
            Heikko, r. Jon Grandpierre (2023, Nemačka, 11’)<br /><br />
            Miasma, r. Petar Tkalec (2024, Srbija, 11’)<br /><br />
            Noydy: The Northern Witches, r. Maria Streltsova (2024, Rusija, 30’)<br /><br />
            Good Friday, r. Andrija Mitrović (2023, Srbija, 20’)<br /><br />
            Trees Painted In Tar, dir. Casper Rudolf (2024, Danska, 14’)<br /><br />
          </p>

          <h3 className='official-selection-21__content-title'>FrontNext generation selekcija</h3>
          <p className='official-selection-21__content-text'>
            Domac, r. Aleksandar Musić (2024, Srbija, 15’)<br /><br />
            Gde ste sestre?, r. Uma Zuban (2024, Srbija, 13’)<br /><br />
            U Limbu, r. Tanja Denčić (2024, Srbija, 4’)<br /><br />
            Osuda, r. Bogdan Moreno (2023, Hrvatska, 20’)<br /><br />
            Moljci, r. Borna Janežić, Mia Vukanović, Anja Peloza, Dominik Velčić, Marul Lerga (2023, Hrvatska, 4’)<br /><br />
            Ko je na mom selfiju?, r. Vid Markovinović (2024, Srbija, 7’)<br /><br />
            Šal, r. Anđela Savić (2024, Srbija, 27’)<br /><br />
          </p>
        </div>
      </div>
    </div>
  )
}
