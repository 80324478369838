import React from 'react'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from "react-i18next";

import HeroComponent from '../components/HeroComponent'

import './OfficialSelectionNews.scss'

export default function OfficialSelectionNews() {

  const { t } = useTranslation();

  const content = t("officialSelection", { returnObjects: true })

  return (
    <div className="official">
      <Helmet>
        <title>{content.title}</title>
      </Helmet>
      <HeroComponent images={content.images} />
      <img src="/assets/images/skate.png" className="official__skate" alt="skate" />
      <div className="content-wrapper">
        <div className="official__wrapper">
          <div className="official__selections">
            {
              content.selections.map((selection, index) => {
                return (
                  <div className="official__selections-selection" key={index}>
                    <h2>{selection.selection}</h2>
                    <div className="official__selections-movies">
                      {
                        selection.movies.map((movie, index) => {

                          const movieInfo = movie.slice(movie.indexOf(',') + 1);

                          const movieName = movie.slice(0, movie.indexOf(','));
                          const director = movieInfo.slice(0, movieInfo.indexOf('('))
                          const homecountry = movieInfo.slice(movieInfo.indexOf('(') + 0)

                          return (
                            <div className="official__selections-movies-row" key={index}>
                              <p className="official__selections-movies-movie-name">{movieName}</p>
                              <p className="official__selections-movies-movie-director">{director}</p>
                              <p className="official__selections-movies-movie">{homecountry}</p>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}
