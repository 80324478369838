import React from 'react'

import './SimpleComponent.scss'

export default function SimpleComponent({ text, color, link }) {
  return (
    <div className="simple-component" style={{ backgroundColor: color }}>
      <div className="content-wrapper">
        <h1
          className="simple-component__title"
          style={{ color: color === 'white' ? '#343F99' : 'white' }}
        >
          {text}
        </h1>
        {
          link && <a className="simple-component__link" href={link.route}><p>{link.text} &#8594;</p></a>
        }
      </div>
    </div>
  )
}