import React from 'react'

import HeroComponent from '../components/HeroComponent'
import SimpleComponent from '../components/SimpleComponent'

import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';

import './Program.scss'

export default function Program() {

  const { t } = useTranslation();
  const content = t("program", { returnObjects: true })
  const language = t('lang');

  return (
    <div className="program">
      <Helmet>
        <title>Program</title>
      </Helmet>

      {
        language === "sr"
          ? <React.Fragment>
            <HeroComponent title="Program" images={content.images} />
            <div className="content-wrapper">
              <div className="program__text" dangerouslySetInnerHTML={{ __html: content.text }}></div>
            </div>
          </React.Fragment>
          : <SimpleComponent text={content.placeholder} color={"white"} />
      }
    </div>
  )
}