import React from 'react'
import HeroComponent from '../components/HeroComponent'
import { Helmet } from 'react-helmet-async'

import './ZombieNews.scss'

export default function ZombieNews() {

  const images = {
    mobile: '/assets/images/news-zombi-setnja-mob.png',
    desktop: '/assets/images/news-zombi-setnja-desktop.png'
  }

  return (
    <div className="zombie">
      <Helmet>
        <title>PRVA NOVOSADSKA ZOMBI ŠETNJA: 30. OKTOBRA U KREATIVNOM DISTRIKTU</title>
      </Helmet>
      <HeroComponent title="PRVA NOVOSADSKA ZOMBI ŠETNJA: 30. OKTOBRA U KREATIVNOM DISTRIKTU" images={images} />
      <div className="content-wrapper">
        <p className="zombie__text">U okviru 20. Međunarodnog festivala kratkometražnog igranog filma Filmski Front, u nedelju, 30. oktobra sa početkom u 18.00 časova, održaće se prva novosadska zombi šetnja. Polazna tačka šetnje biće Studentski kulturni centar Fabrika.<br /> <br /> Događaj koji se organizuje u celom svetu, uoči obeležavanja Noći veštica, napokon stiže i u Novi Sad. Učesnike će šminkati tim pod vođstvom majstora za specijalne filmske efekte Miroslava Lakobrije. Tim između ostalog čine i polaznici radionice SFX maske, koja je realizovana u okviru 20. izdanja festivala.<br /><br /> Šminkanje će početi u 15.00 časova u Studentskom kulturnom centru Fabrika. Za maskiranje, koje je u potpunosti besplatno, možete se prijaviti na <a href="https://docs.google.com/forms/d/e/1FAIpQLSeviyqHW7Y_t1oDPqVFGhHcjTFpUo4dvSBpi6Xl3sxgkGNr6g/viewform?pli=1" target="_blank" rel="noreferrer">OVOM LINKU.</a><br/> <br/> Maskirana masa će u 18.00 časova krenuti u zombi šetnju kroz Kreativni distrikt, pravo do „Route 66“ kao krajnje odrednice – gde će u 19.00 časova početi Zombi žurka. Zombi žurkom ćemo svečano zatvoriti 20. izdanje Filmskog Fronta.<br/> <br/> Zombi šetnja je događaj otvoren za sve građane, a dozvoljeno je i učešće dece uz obaveznu pratnju roditelja.<br/><br/> Međunarodni festival kratkometražnog igranog filma Filmski Front održava se od 27. do 30. oktobra u Kulturnom centru Novog Sada i Kreativnom distriktu. Program FIlmskog Fronta deo je programskog luka &quot;Druga? Evropa&quot; u okviru projekta &quot;Novi Sad - Evropska prestonica kulture&quot;.</p>
      </div>
    </div>
  )
}
