import React from 'react'
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';

import './Sponsors.scss'

export default function Sponsors() {

  const { t } = useTranslation();

  const content = t("sponsors")

  return (
    <div className="sponsors">
      <Helmet>
        <title>{content}</title>
      </Helmet>
      <div className="content-wrapper">
        <div className="sponsors__logos">
          <div className="sponsors__logos-row">
            <a href="http://www.novisad.rs/" className="sponsors__logos-link" target="_blank" rel="noreferrer">
              <img className="sponsors__logos-logo" src="/assets/images/grad-novi-sad-logo.png" alt="Grad Novi Sad" />
            </a>
            <a href="https://kultura.gov.rs/" className="sponsors__logos-link" target="_blank" rel="noreferrer">
              <img className="sponsors__logos-logo" src="/assets/images/srbija-logo.png" alt="Republika Srbija" />
            </a>


            <a href="https://www.fcs.rs/" className="sponsors__logos-link" target="_blank" rel="noreferrer">
              <img className="sponsors__logos-logo" src="/assets/images/filmski-centar-logo.png" alt="Filmski centar Srbije" />
            </a>
            <a href="https://www.kcns.org.rs/" className="sponsors__logos-link" target="_blank" rel="noreferrer">
              <img className="sponsors__logos-logo" src="/assets/images/kulturni-centar-logo.png" alt="Kulturni centar Novog Sada" />
            </a>
            <a href="https://www.euzatebe.rs/" className="sponsors__logos-link" target="_blank" rel="noreferrer">
              <img className="sponsors__logos-logo" src="/assets/images/eu-logo.png" alt="Eu za tebe" />
            </a>


            <a href="https://kinoklubnovisad.org/" className="sponsors__logos-link" target="_blank" rel="noreferrer">
              <img className="sponsors__logos-logo" src="/assets/images/kino-klub-logo.png" alt="Kino klub" />
            </a>
            <a href="https://offns.rs/" className="sponsors__logos-link" target="_blank" rel="noreferrer">
              <img className="sponsors__logos-logo off-logo" src="/assets/images/off-logo.png" alt="Off Novi Sad" />
            </a>
            <a href="https://www.kombuha.rs/" className="sponsors__logos-link" target="_blank" rel="noreferrer">
              <img className="sponsors__logos-logo oblakoder" src="/assets/images/drozli-logo.png" alt="drozli" />
            </a>
            <a href="https://www.rtv.rs/" className="sponsors__logos-link" target="_blank" rel="noreferrer">
              <img className="sponsors__logos-logo rtv-logo" src="/assets/images/rtv-logo.png" alt="rtv" />
            </a>
            <a href="https://radioas.fm/" className="sponsors__logos-link" target="_blank" rel="noreferrer">
              <img className="sponsors__logos-logo asfm-logo" src="/assets/images/asfm-logo.png" alt="radio asfm" />
            </a>
            <a href="https://radiosignal.rs/" className="sponsors__logos-link" target="_blank" rel="noreferrer">
              <img className="sponsors__logos-logo" src="/assets/images/signal-logo.png" alt="radio signal" />
            </a>
            <a href="https://oradio.rs/" className="sponsors__logos-link" target="_blank" rel="noreferrer">
              <img className="sponsors__logos-logo" src="/assets/images/oradi-logo.png" alt="o radio" />
            </a>

            <a href="https://www.oblakoder.org.rs/" className="sponsors__logos-link" target="_blank" rel="noreferrer">
              <img className="sponsors__logos-logo oblakoder" src="/assets/images/oblakoder-logo.png" alt="oblakoder" />
            </a>

            <a href="https://plezirmagazin.net/" className="sponsors__logos-link" target="_blank" rel="noreferrer">
              <img className="sponsors__logos-logo plesir-logo" src="/assets/images/plezir-logo.png" alt="oblakoder" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
