import React from 'react'
import i18n from 'i18next'

import {useLocation, useHistory} from 'react-router-dom'

import { useTranslation } from "react-i18next";
import './LangSwitcher.scss'

export default function LangSwitcher() {
  
  const location = useLocation();

  let history = useHistory();

  const {t} = useTranslation();

  const lang = t('lang')

  const getTranslation = (selectedLanguage, currLang, currRoute) => {

    let translatedRoute = currRoute

    const routesSr = [
      "/vesti",
      "/o-frontu",
      "/program",
      "/galerija",
      "/sponzori",
      "/zvanicna-selekcija"
    ]
  
    const routesEn = [
      "/news",
      "/about",
      "/program",
      "/gallery",
      "/sponsors",
      "/official-selection"
    ]

    if(currLang === 'sr') {
      routesSr.forEach((route, index) => {
        if(route === currRoute) {
          translatedRoute = routesEn[index]
        }
      })
    } 
    else {
      routesEn.forEach((route, index) => {
        if(route === currRoute) {
          translatedRoute = routesSr[index]
        }
      })
    }

    return selectedLanguage ? `${translatedRoute}` : currRoute
  }


  return (
    <div className="lang">
      <div 
        className={`lang__button ${lang === 'sr' ? 'lang__button--active' : ''}`}
        onClick={() => {
          i18n.changeLanguage('sr')
          history.push(getTranslation(lang === 'en', lang, location.pathname))
        }}
      >sr</div>
      <div>/</div>
      <div 
        className={`lang__button ${lang === 'en' ? 'lang__button--active' : ''}`}
        onClick={() => {
          i18n.changeLanguage('en')
          history.push(getTranslation(lang === 'sr', lang, location.pathname))
        }}
      >en</div>
    </div>
  )
}
