import React from 'react'
import { Helmet } from 'react-helmet-async';

import HeroComponent from '../components/HeroComponent'

import './Workshop.scss'

export default function Workshop() {

  const images = {
    mobile: '/assets/images/news-workshop-directing-mob.png',
    desktop: '/assets/images/news-workshop-directing-desktop.png'
  }

  return (
    <div>
      <Helmet>
        <title>Otvorene su prijave za frontovu radionicu režije</title>
      </Helmet>
      <HeroComponent 
        title="OTVORENE SU PRIJAVE ZA FRONTOVU RADIONICU REŽIJE"
        images={images}
      />
      <div className="workshop">
        <div className="content-wrapper">
          <div className="workshop__text">
            <p>Filmski Front i ove godine nastoji da bogatim edukativnim programom motiviše srednjoškolce da zakorače u filmsku industriju.</p>
            <br />
            <p>Te prve korake možeš da načiniš upravo na 20. izdanju festivala, učešćem u Frontovoj radionici režije. Na radionici ćeš imati priliku da se upoznaš sa osnovama filmskog jezika, odnosom scenarija i filma, celokupnim procesom rada na kratkom filmu i slično. Predavač će biti reditelj Danilo Stanimirović.</p>
            <br />
            <p>Radionica će se održati 29. i 30. oktobra u Kulturnom centru Novog Sada, sa početkom u 15.00 časova. Za učešće ti nije potrebno nikakvo prethodno znanje ili iskustvo.</p>
            <br />
            <p>Ukoliko želiš da učestvuješ potrebno je da se prijaviš najkasnije do 23. oktobra 2022. godine tako što ćeš na mejl <a href="mailto:kinoklubnovisad@gmail.com" rel="noreferrer" target="_blank">kinoklubnovisad@gmail.com</a> poslati osnovne podatke: ime i prezime i stečeno obrazovanje.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
