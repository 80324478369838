import React from 'react'
import { useTranslation } from "react-i18next";

import { ReactComponent as Facebook } from '../svg-images/facebook.svg';
import { ReactComponent as Instagram } from '../svg-images/instagram.svg';
import { ReactComponent as Youtube } from '../svg-images/youtube.svg';

import './Footer.scss'

export default function Footer() {

  const { t } = useTranslation();

  return (
    <div className={`footer`}>
      <div className="footer__info">
        <p className="footer__info-contact">{t('footer')}</p>
        <p className="footer__info-email">filmfront@gmail.com</p>
        <div className="footer__info-socials">
          <a href="https://www.facebook.com/filmskifront" target="_blank" rel="noreferrer"><Facebook /></a>
          <a href="https://www.instagram.com/filmskifront/" target="_blank" rel="noreferrer"><Instagram /></a>
          <a href="https://www.youtube.com/c/FilmskiFront" target="_blank" rel="noreferrer"><Youtube /></a>
        </div>
      </div>
      <div className="footer__copy">
        <p>Copyright Filmskifront</p>
        <p>{new Date().getFullYear()}</p>
      </div>
    </div>
  )
}
