import React from 'react'
import { Helmet } from 'react-helmet-async';
import HeroComponent from '../components/HeroComponent'

import "./OfficialSelection21News.scss";

export default function OfficialSelection21News() {

  const images = {
    mobile: '/assets/images/news-zvanicna-selekcija-21-mob.png',
    desktop: '/assets/images/news-zvanicna-selekcija-21.png'
  }

  return (
    <div className='official-selection-21'>
      <Helmet>
        <title>Zvanična selekcija 21. Filmskog Fronta</title>
      </Helmet>

      <HeroComponent title="Zvanična selekcija 21. Filmskog Fronta" images={images} />

      <div className='official-selection-21__content'>
        <div className='content-wrapper'>
          <h3 className='official-selection-21__content-title'>Srbija/region selekcija</h3>
          <p className='official-selection-21__content-text'>
            Fitness Jim, red. Ognjen Isailović (2021, Srbija, 25')<br /><br />
            Boxes, red. Angela Dimeska (2023, S. Makedonija, 15')<br /><br />
            Let Love In, red. Kristina Barišić (2023, Hrvatska, 20')<br /><br />
            Boiling, red. Luka Đikanović (2023, Crna Gora, 27')<br /><br />
            Zof, red. Rino Barbir (2022, Hrvatska, 15')<br /><br />
            Life After Death, red. Ognjen Petković (2022, Srbija, 18')<br /><br />
            Kaže se Čakovec, red. Vanja Nikolić (2023, Hrvatska, 20')<br /><br />
            Očevina, red.  Stefan Tomić (2023, Bosna i Hercegovina, 15')<br /><br />
            Take me, anyplace, red. Nikola Polić (2023, Srbija, 10')<br /><br />
            Niska trava, red. David Gašo (2023, Hrvatska, 26')<br /><br />
            5/3/0, red. Danilo Stanimirović (2023, Srbija, 15')<br /><br />
            Joža, red. Jan Krevatin (2022, Slovenija, 17')<br /><br />
            Kostolomac, red. Saša Poštić (2023, Hrvatska, 20')<br /><br />
            Korak nazad, red. Sofija Nedeljković (2023, Srbija, 23')<br /><br />
            Liga Prvaka, red. Džana Pinjo (2023, Bosna i Hercegovina, 15')<br /><br />
          </p>

          <h3 className='official-selection-21__content-title'>Međunarodna selekcija</h3>
          <p className='official-selection-21__content-text'>
            The Parade, dir. Michail Galanopoulos (2022, Švajcarska/Grčka, 24’)<br /><br />
            Aban, red. Abbas Taheri (2022, Iran, 16’)<br /><br />
            Bergie, red. Dian Weys (2022, Južnoafrička republika, 7’)<br /><br />
            The Weenies, red. Garance Chagnon-Grégoire (2023, Kanada,13’)<br /><br />
            Ode, red. Diego Lisboa (2023, Brazil, 15’)<br /><br />
            A Tortoise’s Year of Fate, red. Yi Xiong (2023, Kina, 20’)<br /><br />
            The Crimson Mercedes, red. Marine Levéel (2022, Francuska, 23’)<br /><br />
            Please Hold the Line, red. Tan Ce Ding (2022, Malezija, 18’)<br /><br />
            Matryoshka, red. Joseph Ros (2023, Kuba, 5’)<br /><br />
            White Ant, red. Shalini Adnani (2023,  Velika Britanija, 14’)<br /><br />
            Loose, red. Javier Pereira (2022, Španija, 20’)<br /><br />
            Things Unheard Of, red. Ramazan Kilic (2023, Turska, 16’)<br /><br />
            Sea Salt, red. Leila Basma (2023, Liban, 19’)<br /><br />
            Fool, red. Mateja Kardelis (2023, Nemačka, 18’)<br /><br />
            Nightmare, red. Evgeniy Kozhemyakin (2023, Rusija, 20’)<br /><br />
            Kharkiv Music Fest Did Happen, red. Vyacheslav Turyanytsya (2023, Ukrajina, 11’)<br /><br />
            Ice Cream. Sladoled, red. Steve Spremo (2022, Sjedinjene Američke Države, 13’)<br /><br />
            Distance, red. Iver Innset (2023, Norveška, 15’)<br /><br />
          </p>

          <h3 className='official-selection-21__content-title'>AnimaFront selekcija</h3>
          <p className='official-selection-21__content-text'>
            Iza vidljivog, red. Dana Vukajlović (2023, Srbija, 10')<br /><br />
            Shaptach, red. Mladen Đukić (2023, Bosna i Hercegovina, 4')<br /><br />
            Porodični portret, red. Lea Vidaković (2023, Hrvatska, 14')<br /><br />
            The Legend of Goldhorn, red. Lea Vučko (2022, Slovenija, 14')<br /><br />
            Sveti ljudi, red. David Lovrić (2022, Hrvatska, 11')<br /><br />
            Pobeći od Irene, red. Irena Antin (2023, Srbija, 7')<br /><br />
            Sjeti se kako sam jahala bijelog konja, red. Ivana Bošnjak Volda (2022, Hrvatska, 10')<br /><br />
            Mouse House, red. Timon Leder (2022, Slovenija, 9')<br /><br />
            Pozdrav suncu, red. Darko Masnec (2022, Hrvatska, 9')<br /><br />
            11, red. Vuk Jevremović (2022, Hrvatska, 5')<br /><br />
          </p>

          <h3 className='official-selection-21__content-title'>HororFront selekcija</h3>
          <p className='official-selection-21__content-text'>
            Stoper u noći, red. David Kolaković (2022, Srbija, 29’)<br /><br />
            The Men’s Silence, red. Gabriel Duarte (2023, Brazil, 3’)<br /><br />
            Signals, red. Filip Martinović (2023, Estonija, 12’)<br /><br />
            There's only one, red. Mik J. López (2022, Španija, 16’)<br /><br />
            Business, red. Max Coolish, (2023, Portugal, 6’)<br /><br />
            Incarnation, red. Bernard Winter (2023, Australija, 14’)<br /><br />
          </p>
        </div>
      </div>
    </div>
  )
}
